<template>
	<div class="pd20">
		<a-spin :spinning="loading">
			<div class=" ">
				<a-form layout="inline">
					<a-form-item label="订单编号">
						<a-input v-model="search.order_id" placeholder="请输入订单编号"></a-input>
					</a-form-item>
					
					<a-form-item label="客户手机号">
						<a-input v-model="search.mobile" placeholder="请输入客户手机号"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
			
			<div class="mt20">
				<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
					<a-radio-button :value="1">
						待付款
					</a-radio-button>
					<a-radio-button :value="4">
						<div class="flex" style="position: relative;">待到店自提
							<div class="tag-num positon" v-if="write_off_num > 0">{{write_off_num}}</div>
						</div>
						
					</a-radio-button>
					<a-radio-button :value="8">
						已完成
					</a-radio-button>
					<a-radio-button :value="-1">
						已取消
					</a-radio-button>
				</a-radio-group>
			</div>
			
			<div class="mt30" v-if="datas.length>0">
				<div class="mall-store-goods-order-item" v-for="(order,index) in datas">
					<div class="mall-store-goods-order-item-header">
						<div class="flex">
							<div style="width: 85%;" class="flex alcenter ">
								<div class="ml20 ">
									<div class="flex alcenter">
										<div class="flex alcenter">
											<div class="ft14 ftw400 cl-info text-over4">订单编号：</div>
											<div class="ft14 ftw600 cl-main text-over4">{{order.store_order_id}}</div>
										</div>
										
										<div class="flex alcenter ml40" v-if="order.member">
											<div class="ft14 ftw400 cl-info text-over4">下单客户：</div>
											<div class="ft14 ftw600 cl-main text-over4">{{order.member.nick_name}} {{order.member.mobile}}</div>
										</div>
										
										<div class="flex alcenter ml40">
											<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
											<div class="ft14 ftw600 cl-main text-over4">{{order.pay_type_mean}}</div>
										</div>
										
										<div class="flex alcenter ml40">
											<div class="ft14 ftw400 cl-info text-over4">赠送礼品：</div>
											<div class="ft14 ftw600 cl-theme text-over4">{{order.goods_gift_giving.length}}个，总价值¥{{order.gift_total_price}}</div>
										</div>
									</div>
								</div>
							</div>
							<div style="width: 15%" class="flex center">操作</div>
						</div>
					</div>
					<div>
						<div class="flex">
							<div style="width: 40%;" >
								<div>
									<div class="mall-store-goods-order-item-goods">
										<div class="flex space alcenter">
											<div class="flex alcenter" style="width: 80%;">
												<img v-if="order.goods_cover_img != null" :src="order.goods_cover_img" style="width: 60px;height: 60px;"/>
												<div class="ml10">
													<div class="ft14 ftw500 cl-info" style="width: 90%;">
														{{order.goods_name}}
													</div>
												</div>
											</div>
											<div class="flex alcenter ft14 ftw500 cl-info"style="width: 10%;">
												<div>数量</div>
												<div class="ml8">{{order.goods_num}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="width: 60%;" class="flex">
								<div class="flex alcenter center" style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
									<div class="ft12 ftw400 cl-info">
										<div class="text-center">合计：¥{{order.goods_total_price}}</div>
										
										<div class="mt5 text-center"
											v-if="order.pay_type=='offline' && order.youhui_balance > 0">
											优惠金额：¥{{order.youhui_balance}}</div>
										<div class="ft14 ftw500 cl-black mt5 text-center">
											实付金额：¥{{order.need_pay}}</div>
									</div>
								</div>
								<div class="flex alcenter center" style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
									<div class="ft12 ftw400 cl-info">
										<div>
											<div class="text-center">下单时间</div>
											<div class="mt4 text-center">{{order.add_time_format}}</div>
										</div>
										<div class="mt12">
											<div class=" text-center">支付时间</div>
											<div class="ft14 ftw500  mt4 text-center">{{order.pay_time_format}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter center" style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-info">
										{{order.status_means}}
									</div>
								</div>
								<div class="flex alcenter center" style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-info">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay">
												  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="see(index)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">查看订单</span>
													</a>
												  </a-menu-item>
											</a-menu>
										  </a-dropdown>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="mt30 flex center" v-else>
				<a-empty />
			</div>
			
			<div class="flex center mt20">
				<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current"
					:pageSize="pagination.pageSize" show-less-items />
			</div>			
		</a-spin>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 5, //每页中显示10条数据
					total: 0,
				},
				search: {
					order_id: '',
					status: 1,
					mobile: '',
				},
				datas:[],
				write_off_num:0,
				
			}
		},
		methods: {
			
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getStoreOrderList', {
					order_id: this.search.order_id,
					status: this.search.status,
					mobile: this.search.mobile,
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res => {
					this.write_off_num=res.write_off_num;
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			
			changeStatus() {
				this.pagination.current = 1;
				this.getLists();
			},
			
			see(index){
				this.$router.push('/mall/storeOrderDetail?id='+this.datas[index].store_order_id);
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.mall-store-goods-order-item{
		width: 100%;
		margin-bottom: 20px;
	}
	
	.mall-store-goods-order-item-header{
		width: 100%;
		height: 50px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		line-height: 50px;
	}
	
	.mall-store-goods-order-item-tag{
		padding: 1px 8px;
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}
	
	.mall-store-goods-order-item-goods{
		padding: 30px 20px;
		border: 1px solid #EBEDF5;
		border-top: none;
	}
	.mall-store-goods-order-item-goods.tag{
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
</style>
